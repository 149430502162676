import React from 'react';
import classNames from 'classnames';
import { ModalProps } from 'semantic-ui-react';
import { ATMModal } from '../../atoms/ATMModal/ATMModal.component';
import { ATMIcon } from '../../atoms/ATMIcon/ATMIcon.component';
import { ATMHeader } from '../../atoms/ATMHeader/ATMHeader.component';
import styles from './MOLRightPanel.module.scss';
import { ATMPopover } from '../../atoms/ATMPopover/ATMPopover.component';

export type IMOLRightPanelProps = ModalProps & {
  panelContent?: React.ReactNode;
  content?: React.ReactNode;
  header?: React.ReactNode;
  popoverHeader?: string;
  popoverContent?: string;
  subHeaderContent?: React.ReactNode;
  panelFooter?: React.ReactNode;
  footer?: React.ReactNode;
  width?: string;
  isOpen?: boolean;
  socalGas?: boolean;
  helpButtonContent?: JSX.Element;
  crosscloseicon?: boolean;
};

const MOLRightPanel: React.FC<React.PropsWithChildren<IMOLRightPanelProps>> = ({
  panelContent,
  content,
  crosscloseicon = true,
  header,
  popoverHeader,
  popoverContent,
  subHeaderContent,
  panelFooter,
  footer,
  width = '40%',
  isOpen = false,
  socalGas = false,
  helpButtonContent,
  children,
  ...props
}) => {
  return (
    <ATMModal
      dimmer="inverted"
      style={{ width }}
      {...props}
      className={classNames(styles.floatingPanel, props.className)}
      open={props.open || isOpen}
    >
      {header && (
        <ATMModal.Header className={styles.noPadding}>
          {typeof header !== 'string' ? (
            header
          ) : (
            <div>
              <div className={styles.panelHeader}>
                <ATMHeader as="h1" className={styles.textMargin}>
                  {header}
                </ATMHeader>
                <div className={styles.flex}>
                  {popoverContent && (
                    <ATMPopover
                      on="click"
                      position="bottom left"
                      className={styles.popoverHeight}
                      basic
                      content={popoverContent}
                      header={popoverHeader}
                      trigger={
                        <ATMIcon name="question circle outline" size="small" />
                      }
                    />
                  )}
                  {socalGas && helpButtonContent}
                  {crosscloseicon && (
                    <div>
                      <ATMIcon
                        name="delete"
                        onClick={props.onClose}
                        size="small"
                        className={styles.onHoverCursor}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </ATMModal.Header>
      )}

      <ATMModal.Content scrolling className={styles.floatPanelContent}>
        {subHeaderContent && (
          <div className={styles.panelSubHeader}>{subHeaderContent}</div>
        )}
        <div className={styles.contentArea}>
          {content || panelContent || children}
        </div>
      </ATMModal.Content>
      {(footer || panelFooter) && (
        <ATMModal.Actions className={styles.rightPanelFooter}>
          {footer || panelFooter}
        </ATMModal.Actions>
      )}
    </ATMModal>
  );
};

export { MOLRightPanel };
