import React from 'react';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import classNames from 'classnames';
import styles from './ATMToggle.module.scss';
import { customSize } from '../ATMDropdown/ATMDropdown.component';

export type IATMToggleProps = CheckboxProps & {
  size?: customSize;
};

export const ATMToggle: React.FC<IATMToggleProps> = ({
  children,
  size,
  ...props
}) => {
  return (
    <Checkbox
      toggle
      {...props}
      className={classNames(
        props.className,
        size === 'mini' && 'mini',
        size === 'tiny' && 'tiny',
        size === 'small' && 'small',
        size === 'medium' && 'medium',
        size === 'large' && 'large',
        {
          [styles.mini]: size === 'mini',
          [styles.tiny]: size === 'tiny',
          [styles.small]: size === 'small',
          [styles.medium]: size === 'medium',
          [styles.large]: size === 'large',
          [styles.toggleContainer]: size === undefined,
        }
      )}
    />
  );
};
