import React from 'react';
import {
  Button,
  ButtonProps,
  ButtonOrProps,
  ButtonGroupProps,
} from 'semantic-ui-react';
import classNames from 'classnames';
import styles from './ATMButton.module.scss';

export type iconcolors = 'blue' | 'grey' | 'black';

export type IATMButtonMyProps = ButtonProps & {
  iconcolor?: iconcolors;
  noborder?: boolean;
  borderonhover?: boolean;
};

export interface IATMButton extends React.FC<IATMButtonMyProps> {
  Group: typeof Button.Group;
  Content: typeof Button.Content;
  Or: typeof Button.Or;
}

const ATMButton: IATMButton = ({ children, ...props }) => {
  const propsIsEmpty = Object.keys(props).length === 0;
  if (props.iconcolor || props.noborder || props.borderonhover) {
    return (
      <Button
        {...props}
        size={props.size ? props.size : 'small'}
        className={classNames(props.className, {
          [styles.blue]: props.iconcolor === 'blue',
          [styles.grey]: props.iconcolor === 'grey',
          [styles.black]: props.iconcolor === 'black',
          [styles.noborder]: props.noborder,
          [styles.borderonhover]: props.borderonhover,
        })}
      >
        {children}
      </Button>
    );
  }
  if (propsIsEmpty) {
    return (
      <Button size="small" secondary>
        {children}
      </Button>
    );
  }
  return (
    <Button size={props.size ? props.size : 'small'} {...props}>
      {children}
    </Button>
  );
};

ATMButton.Group = Button.Group;
ATMButton.Content = Button.Content;
ATMButton.Or = Button.Or;

export type IATMButtonProps = ButtonProps;
export type IATMButtonGroupProps = ButtonGroupProps;
export type IATMButtonOrProps = ButtonOrProps;

export { ATMButton };
